<template>
  <div class="justify-center d-flex">
    <v-card width="400px">
      <v-card-title class="grey lighten-3 flex-column align-start"
        >Créer un nouveau mot de passe</v-card-title
      >
      <v-divider></v-divider>
      <v-card-text>
        <p>
          Choisissez votre nouveau mot de passe pour vous connecter à Focalia.
        </p>
        <div v-if="$store.state.account.user" class="mb-2">
          <b>Adresse email :</b> {{ $store.state.account.user.user.email }}
        </div>
        <v-form @submit.prevent.stop="submit">
          <TextField
            :field="form.password"
            label="Mot de passe"
            type="password"
            outlined
            dense
          ></TextField>
          <TextField
            :field="form.password2"
            label="Confirmez le mot de passe"
            type="password"
            outlined
            dense
          ></TextField>
          <v-btn type="submit" class="d-none"></v-btn>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn
          text
          class="ml-auto"
          @click="submit"
          :loading="loading"
          color="success"
          >Sauvegarder</v-btn
        >
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { requestService } from '@/services/request.service'
import TextField from '@/components/form/TextField'
import Validation from '@/components/mixins/Validation'

export default {
  name: 'ResetPassword',
  components: { TextField },
  mixins: [Validation],
  data() {
    return {
      form: {
        password: {
          value: '',
          required: true,
        },
        password2: {
          value: '',
          required: true,
        },
      },
      loading: false,
    }
  },
  methods: {
    submit() {
      this.formValidation(this.form, true)
      if (this.errorsNbr > 0) {
        return
      }
      if (this.form.password2.value !== this.form.password.value) {
        this.$set(this.form.password2, 'errors', [
          'Les mots de passe ne correspondent pas',
        ])
        return
      }
      this.loading = true
      requestService
        .post('api/user/change-password', {
          password: this.form.password.value,
        })
        .then(response => {
          if (!response.success) {
            this.$set(this.form.password, 'errors', [response.message])
          } else {
            this.$store.dispatch(
              'alert/success',
              'Mot de passe modifié avec succès',
            )
            this.$router.push({ name: 'Home' })
          }
        })
        .finally(() => (this.loading = false))
    },
  },
}
</script>

<style scoped></style>
